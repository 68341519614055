import { defineStore } from "pinia";

export const useTripStore = defineStore("tripStore", {
  state: () => ({
    trips: [],
    loaded: false,
  }),
  actions: {
    // * Set the trip list and loaded state
    setTrips(list, loaded) {
      this.trips = list;
      this.loaded = loaded;
    },

    // * Update the details of a trip by id
    updateTripDetails(id, updatedDetails) {
      const trip = this.trips.find((r) => r.id === id);
      if (trip) {
        Object.assign(trip, updatedDetails);
      } else {
        throw new Error("Trip not found");
      }
    },

    // * Delete a trip by id
    deleteTrip(id) {
      this.trips = this.trips.filter((r) => r.id !== id);
    },
  },
});
