/* eslint-disable no-unused-vars */
// * https://firebase.google.com/docs/web/setup#available-libraries

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  Timestamp,
  doc,
  setDoc,
  getDocs,
  query,
  orderBy,
  limit,
  getDoc,
  where,
  startAfter,
  writeBatch,
  arrayUnion,
  updateDoc,
  deleteDoc,
  getCountFromServer,
  arrayRemove,
  serverTimestamp,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

// * Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDuoVBZRWL-FsLVLLcq9_GDUdo1VhFGbEg",
  authDomain: "nectar-and-gold-safaris.firebaseapp.com",
  projectId: "nectar-and-gold-safaris",
  storageBucket: "nectar-and-gold-safaris.appspot.com",
  messagingSenderId: "213339384354",
  appId: "1:213339384354:web:ec58091fd1a148a855787b",
  measurementId: "G-JLK69GPGV8",
};

// * Stores
import { useRequestStore } from "@/store/requestStore";
import { useClientStore } from "@/store/clientStore";
import { useTripStore } from "@/store/tripStore";

// * Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const firestore = getFirestore(app);
const storage = getStorage();
const auth = getAuth();
auth.useDeviceLanguage();
export { auth, onAuthStateChanged };

// * Add message to firestore
export async function sendMessage(name, email, phone, message) {
  try {
    const messagesCollection = collection(firestore, "Messages");

    await addDoc(messagesCollection, {
      name,
      email,
      phone,
      message,
      status: "unread",
      date: Timestamp.now(),
    });

    return { success: true };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error sending message:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Add trip plan to firestore
export async function planYourTrip(
  full_name,
  email,
  phone,
  residence,
  budget,
  more_info
) {
  try {
    const tripPlanCollection = collection(firestore, "TripPlan");

    await addDoc(tripPlanCollection, {
      full_name,
      email,
      phone,
      residence,
      budget,
      more_info,
      status: "unread",
      date: Timestamp.now(),
    });

    return { success: true };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error adding trip plan:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Function to subscribe an email
export async function subscribe(email) {
  try {
    // * Reference to the "Email" collection
    const emailsCollection = collection(firestore, "Subscribers");

    const queryRef = query(
      emailsCollection,
      where("email", "==", email),
      limit(1)
    );
    const querySnapshot = await getDocs(queryRef);
    if (querySnapshot.docs.length === 0) {
      // * Add the email to the collection
      await addDoc(emailsCollection, { email });
      return { success: true };
    } else {
      return { success: false, error: "You are already subscribed" };
    }
  } catch (error) {
    console.error("Error subscribing email:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Function to unsubscribe an email
export async function callUnsubscribe(email) {
  try {
    const unsubscribe = httpsCallable(functions, "unsubscribe");
    const result = await unsubscribe({ email });
    return { ...result.data };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Function to signin with email & password
export async function signInWithEP(email, password) {
  return signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      const idTokenResult = await userCredential.user.getIdTokenResult(true);
      console.log(idTokenResult.claims.role);
      const role =
        idTokenResult.claims && idTokenResult.claims.role
          ? idTokenResult.claims.role
          : "user";
      return { success: true, role };
    })
    .catch((error) => {
      console.error("Error signing in :", error);
      return { success: false, error: error.message || "An error occurred" };
    });
}

// *Function to create new account with email & password
export async function newAccountWithEP(name, email, password) {
  await createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      await updateProfile(userCredential.user, {
        displayName: name,
      }).then(async () => {
        const user = userCredential.user;
        const uid = user.uid;
        const displayName = user.displayName;
        const userEmail = user.email;

        const documentRef = doc(firestore, "Users", uid);
        try {
          await setDoc(documentRef, {
            email: userEmail,
            displayName,
            photoURL: null,
            description: null,
            links: [],
            role: "user",
            dateJoined: Timestamp.now(),
          });
          return { success: true };
        } catch (error) {
          console.error("Error creating user doc:", error.message);
          return {
            success: false,
            error: error.message || "Error creating user doc:",
          };
        }
      });
    })
    .catch((error) => {
      console.error("Error creating user account:", error);
      return { success: false, error: error.message || "An error occurred" };
    });
  return { success: true };
}

// * Function to signout
export async function signout() {
  return signOut(auth)
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      return { success: false, error: error.message || "An error occurred" };
    });
}

// * get user doc
export async function getUserDoc(uid) {
  try {
    const userDocRef = doc(firestore, "Users", uid);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      return { success: true, user: { id: uid, ...userDocSnapshot.data() } };
    } else {
      return { success: false, error: "User not found" };
    }
  } catch (error) {
    console.error("Error getting user doc:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Update user
export async function updateUser(user) {
  try {
    if (user.photoURL && user.photoURL instanceof Blob) {
      const photoStorageRef = ref(storage, `displayPicture/${user.id}`);
      await uploadBytes(photoStorageRef, user.photoURL);
      user.photoURL = await getDownloadURL(photoStorageRef);
    }
    const functionCall = httpsCallable(functions, "updateUser");
    const result = await functionCall({
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      description: user.description,
      links: user.links,
    });
    return { ...result.data };
  } catch (error) {
    console.log(error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Get all the articles docs
export async function getAllArticles(articlesLimit, lastDoc, tag) {
  try {
    const articles = [];
    let queryRef = collection(firestore, "Articles");

    if (tag && tag !== "all") {
      queryRef = query(queryRef, where("tag", "==", tag));
    }
    queryRef = query(queryRef, orderBy("timestamp", "desc"));

    if (lastDoc) {
      queryRef = query(queryRef, startAfter(lastDoc));
    }
    queryRef = query(queryRef, limit(articlesLimit || 20));

    const querySnapshot = await getDocs(queryRef);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      articles.push({ ...doc.data(), id: doc.id });
    });
    return { success: true, articles, lastDoc: lastVisible };
  } catch (error) {
    console.error("Error getting articles :", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Get user the articles docs
export async function getUserArticles(articlesLimit, lastDoc, uid) {
  try {
    const articles = [];
    let queryRef = collection(firestore, "Articles");
    if (uid) {
      queryRef = query(queryRef, where("authorId", "==", uid));
      queryRef = query(queryRef, orderBy("timestamp", "asc"));
    } else {
      queryRef = query(queryRef, orderBy("timestamp", "asc"));
    }

    if (lastDoc) {
      queryRef = query(queryRef, startAfter(lastDoc));
    }
    queryRef = query(queryRef, limit(articlesLimit || 20));

    const querySnapshot = await getDocs(queryRef);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      articles.push({ ...doc.data(), id: doc.id });
    });
    return { success: true, articles, lastDoc: lastVisible };
  } catch (error) {
    console.error("Error getting articles :", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Get Article by id
export async function getArticle(id) {
  try {
    const articleDocRef = doc(firestore, "Articles", id);
    const articleDocSnapshot = await getDoc(articleDocRef);
    if (articleDocSnapshot.exists()) {
      return {
        success: true,
        article: { id: id, ...articleDocSnapshot.data() },
      };
    } else {
      return { success: false, error: "Article not found" };
    }
  } catch (error) {
    console.error("Error getting article:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Create new Article
export async function addArticle(article) {
  const user = auth.currentUser;
  if (user) {
    try {
      var docValues = {
        title: article.title,
        image: null,
        authorName: user.displayName,
        content: article.content,
        timestamp: Timestamp.now(),
        readTime: article.readTime,
        authorId: user.uid,
        tag: article.tag,
      };
      if (article.image) {
        const imageStorageRef = ref(storage, `articleImages/${article.title}`);
        await uploadBytes(imageStorageRef, article.image);
        docValues.image = await getDownloadURL(imageStorageRef);
      }

      await addDoc(collection(firestore, "Articles"), docValues);
      return { success: true };
    } catch (error) {
      console.log(error);
      return { success: false, error: error.message || "An error occurred" };
    }
  } else {
    console.log("Login to add a new article");
    return { success: false, error: "Login to add a new article" };
  }
}

// * Update Article
export async function updateArticle(article) {
  const user = auth.currentUser;
  if (user) {
    try {
      if (article.id) {
        var docValues = {
          title: article.title,
          image: null,
          authorName: article.authorName,
          content: article.content,
          timestamp: Timestamp.now(),
          readTime: article.readTime,
          authorId: article.authorId,
          tag: article.tag,
        };
        const articleDocRef = doc(firestore, "Articles", article.id);
        if (article.image && article.image instanceof Blob) {
          const imageStorageRef = ref(
            storage,
            `articleImages/${article.title}`
          );
          await uploadBytes(imageStorageRef, article.image);
          docValues.image = await getDownloadURL(imageStorageRef);
        } else {
          docValues.image = article.image;
        }
        await updateDoc(articleDocRef, docValues);
        return { success: true };
      } else {
        console.log("No Article id provided");
        return { success: false, error: "Something went wrong" };
      }
    } catch (error) {
      console.log(error);
      return { success: false, error: error.message || "An error occurred" };
    }
  } else {
    console.log("Login to update a new article");
    return { success: false, error: "Login to update a new article" };
  }
}

// * Update Role
export async function updateRole(email, role) {
  try {
    const functionCall = httpsCallable(functions, "updateRole");
    const result = await functionCall({ email, role });
    return { ...result.data };
  } catch (error) {
    console.log(error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * get all users
export async function getUsers(userLimit, lastDoc) {
  try {
    const users = [];
    let queryRef = collection(firestore, "Users");

    queryRef = query(queryRef, orderBy("dateJoined", "desc"));
    if (lastDoc) {
      queryRef = query(queryRef, startAfter(lastDoc));
    }
    queryRef = query(queryRef, limit(userLimit || 20));

    const querySnapshot = await getDocs(queryRef);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      users.push({ ...doc.data(), id: doc.id });
    });
    return { users, lastDoc: lastVisible };
  } catch (error) {
    console.error("Error getting users:", error);
    return {};
  }
}

// * Get total subscribers
export async function getSubscriberCount() {
  try {
    const subscribersCollection = collection(firestore, "Subscribers");
    const snapshot = await getCountFromServer(subscribersCollection);

    return { success: true, count: snapshot.data().count };
  } catch (error) {
    console.error("Error getting subscriber count:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Get all newsletters
export async function getNewsletters(newsletterLimit, lastDoc) {
  try {
    const newsletters = [];
    let queryRef = collection(firestore, "Newsletters");

    queryRef = query(queryRef, orderBy("date", "desc"));
    if (lastDoc) {
      queryRef = query(queryRef, startAfter(lastDoc));
    }
    queryRef = query(queryRef, limit(newsletterLimit || 10));

    const querySnapshot = await getDocs(queryRef);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      newsletters.push({ ...doc.data(), id: doc.id });
    });
    return { newsletters, lastDoc: lastVisible };
  } catch (error) {
    console.error("Error getting newsletters:", error);
    return {};
  }
}

// * Send a newsletter
export async function sendNewsletter(html, subject) {
  try {
    const newsletterCollection = collection(firestore, "Newsletters");

    await addDoc(newsletterCollection, {
      html,
      subject,
      status: "new",
      date: Timestamp.now(),
    });

    return { success: true };
  } catch (error) {
    console.error("Error sending newsletter:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Get all messages
export async function getMessages(messageLimit, lastDoc) {
  try {
    const messages = [];
    let queryRef = collection(firestore, "Messages");

    queryRef = query(queryRef, orderBy("date", "desc"));
    if (lastDoc) {
      queryRef = query(queryRef, startAfter(lastDoc));
    }
    queryRef = query(queryRef, limit(messageLimit || 20));

    const querySnapshot = await getDocs(queryRef);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      messages.push({ ...doc.data(), id: doc.id });
    });
    return { messages, lastDoc: lastVisible };
  } catch (error) {
    console.error("Error getting messages:", error);
    return {};
  }
}

// * Get unread messages
export async function getUnreadMessages(messageLimit, lastDoc) {
  try {
    const messages = [];
    let queryRef = collection(firestore, "Messages");

    queryRef = query(
      queryRef,
      where("status", "==", "unread"),
      orderBy("date", "desc")
    );
    if (lastDoc) {
      queryRef = query(queryRef, startAfter(lastDoc));
    }
    queryRef = query(queryRef, limit(messageLimit || 20));

    const querySnapshot = await getDocs(queryRef);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      messages.push({ ...doc.data(), id: doc.id });
    });
    return { messages, lastDoc: lastVisible };
  } catch (error) {
    console.error("Error getting unread messages:", error);
    return {};
  }
}

// * Mark a message as read
export async function markMessageAsRead(messageId) {
  try {
    const messageDocRef = doc(firestore, "Messages", messageId);
    await updateDoc(messageDocRef, {
      status: "read",
    });
    return { success: true };
  } catch (error) {
    console.error("Error updating message status:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Reply to a message
export async function replyToMessage(messageId, message, subject) {
  try {
    const messageDocRef = doc(firestore, "Messages", messageId);
    await updateDoc(messageDocRef, {
      replyDetails: {
        message,
        subject,
      },
      status: "replied",
    });
    return { success: true };
  } catch (error) {
    console.error("Error replying to message:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Delete message
export async function deleteMessage(id) {
  try {
    const messageDocRef = doc(firestore, "Messages", id);
    await deleteDoc(messageDocRef);
    return { success: true };
  } catch (error) {
    console.error("Error deleting message:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Delete article
export async function deleteArticle(id) {
  try {
    const articleDocRef = doc(firestore, "Articles", id);
    await deleteDoc(articleDocRef);
    return { success: true };
  } catch (error) {
    console.error("Error deleting article:", error);
    return { success: false, error: error.article || "An error occurred" };
  }
}

// * Get all Trip Requests
export async function getAllRequests() {
  var requests = [];
  try {
    const collectionRef = collection(firestore, "TripPlan");
    let queryRef = query(collectionRef, orderBy("date", "desc"));
    const querySnapshot = await getDocs(queryRef);
    querySnapshot.forEach((doc) => {
      requests.push({ ...doc.data(), id: doc.id });
    });
  } catch (error) {
    console.error("Error getting requests :", error);
  }
  return requests;
}

// * change request status
export async function changeRequestStatus(requestId, status) {
  if (status !== "read" && status !== "unread") {
    return { success: false, error: "Invalid status" };
  }
  try {
    const requestDocRef = doc(firestore, "TripPlan", requestId);
    await updateDoc(requestDocRef, {
      status,
    });
    const requestStore = useRequestStore();
    requestStore.updateStatus(requestId, status);
    return { success: true };
  } catch (error) {
    console.error("Error updating request status:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Delete request
export async function deleteRequest(id) {
  try {
    const requestDocRef = doc(firestore, "TripPlan", id);
    await deleteDoc(requestDocRef);
    const requestStore = useRequestStore();
    requestStore.deleteRequest(id);

    return { success: true };
  } catch (error) {
    console.error("Error deleting request:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Reply to a request
export async function replyToRequest(requestId, message, subject) {
  try {
    const requestDocRef = doc(firestore, "TripPlan", requestId);
    await updateDoc(requestDocRef, {
      replyDetails: {
        message,
        subject,
      },
    });
    return { success: true };
  } catch (error) {
    console.error("Error replying to request:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Create a client doc
export async function createClient(
  name,
  email,
  phone,
  residence = null,
  displayPicture = null,
  notes = null
) {
  try {
    const clientCollection = collection(firestore, "Clients");
    var imgUrl = null;
    if (displayPicture) {
      const imageStorageRef = ref(storage, `clients/${name}`);
      await uploadBytes(imageStorageRef, displayPicture);
      imgUrl = await getDownloadURL(imageStorageRef);
    }

    const result = await addDoc(clientCollection, {
      name,
      email,
      phone,
      residence,
      displayPicture: imgUrl,
      notes,
      dateCreated: Timestamp.now(),
    });
    const clientStore = useClientStore();
    clientStore.addClient({
      id: result.id,
      name,
      email,
      phone,
      residence,
      displayPicture: imgUrl,
      notes,
      dateCreated: Timestamp.now(),
    });
    return { success: true, id: result.id };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error creating client:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Create a Trip doc
export async function createTrip(clientId) {
  try {
    const tripCollection = collection(firestore, "Trips");

    const result = await addDoc(tripCollection, {
      clientId,
      dateCreated: Timestamp.now(),
    });

    return { success: true, id: result.id };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error creating trip:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Accept request
export async function acceptRequest(request) {
  try {
    const clientCreation = await createClient(
      request.full_name,
      request.email,
      request.phone,
      request.residence
    );
    if (!clientCreation.success) {
      console.error("Error creating client:", clientCreation.error);
      return {
        success: false,
        error: clientCreation.error || "An error occurred",
      };
    }

    const tripCreation = await createTrip(clientCreation.id);
    if (!tripCreation.success) {
      console.error("Error creating trip:", tripCreation.error);
      return {
        success: false,
        error: tripCreation.error || "An error occurred",
      };
    }

    await deleteRequest(request.id);

    return { success: true, tripId: tripCreation.id };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error accepting request:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Get all clients
export async function getAllClients() {
  var clients = [];
  try {
    const collectionRef = collection(firestore, "Clients");
    let queryRef = query(collectionRef, orderBy("dateCreated", "desc"));
    const querySnapshot = await getDocs(queryRef);
    querySnapshot.forEach((doc) => {
      clients.push({ ...doc.data(), id: doc.id });
    });
  } catch (error) {
    console.error("Error getting clients :", error);
  }
  return clients;
}

// * Update client
export async function updateClient(client) {
  try {
    if (client.displayPicture && client.displayPicture instanceof Blob) {
      const photoStorageRef = ref(storage, `clients/${client.id}`);
      await uploadBytes(photoStorageRef, client.displayPicture);
      client.displayPicture = await getDownloadURL(photoStorageRef);
    }
    var docValues = {
      name: client.name,
      email: client.email,
      phone: client.phone,
      timestamp: Timestamp.now(),
      displayPicture: client.displayPicture,
      notes: client.notes,
      residence: client.residence,
    };
    const clientDocRef = doc(firestore, "Clients", client.id);
    await updateDoc(clientDocRef, docValues);
    const clientStore = useClientStore();
    clientStore.updateClientDetails(client.id, docValues);
    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Delete client
export async function deleteClient(id) {
  try {
    const clientDocRef = doc(firestore, "Clients", id);
    await deleteDoc(clientDocRef);
    const clientStore = useClientStore();
    clientStore.deleteClient(id);
    return { success: true };
  } catch (error) {
    console.error("Error deleting client:", error);
    return { success: false, error: error.client || "An error occurred" };
  }
}

// * Get all trips
export async function getAllTrips() {
  var trips = [];
  try {
    const collectionRef = collection(firestore, "Trips");
    let queryRef = query(collectionRef, orderBy("dateCreated", "desc"));
    const querySnapshot = await getDocs(queryRef);
    querySnapshot.forEach((doc) => {
      trips.push({ ...doc.data(), id: doc.id });
    });
  } catch (error) {
    console.error("Error getting trips :", error);
  }
  return trips;
}

// * Delete trip
export async function deleteTrip(id) {
  try {
    const tripDocRef = doc(firestore, "Trips", id);
    await deleteDoc(tripDocRef);
    const tripStore = useTripStore();
    tripStore.deleteTrip(id);
    return { success: true };
  } catch (error) {
    console.error("Error deleting trip:", error);
    return { success: false, error: error.trip || "An error occurred" };
  }
}
